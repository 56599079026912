import { AddressDTO } from "../../../../../../dtos/addressDTO";

export const formatLocationAddress = (address: AddressDTO): string => {
  if (!address) return "";
  return `${address.streetName} ${address.number}, ${address.zipCode} ${address.cityName}`;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  function addZeroBefore(value: number) {
    return `${value < 10 ? "0" : ""}${value}`;
  }

  return `${addZeroBefore(date.getHours())}:${addZeroBefore(
    date.getMinutes()
  )} - ${addZeroBefore(date.getDate())}/${addZeroBefore(
    date.getMonth()
  )}/${date.getFullYear()}`;
};
