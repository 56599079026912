import React from "react";
import { ZoneCardModalProps } from "./props";
import "./styles.css";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import ZoneCardModalCompanyOrder from "./component/company-order";

const ZoneCardModal = ({ zone, closeModal }: ZoneCardModalProps) => {
  return (
    <div>
      <div className="zone-card-modal-header">
        <CloseIcon
          style={{ alignSelf: "end", cursor: "pointer" }}
          onClick={closeModal}
        />
        <div className="zone-card-modal-header-title">{`${zone.name} - companies`}</div>
      </div>
      <div className="zone-card-modal-body">
        {zone?.companyZoneDto?.companyIds?.map((id, index) => {
          return <ZoneCardModalCompanyOrder id={id} order={index + 1} />;
        })}
      </div>
    </div>
  );
};

export default ZoneCardModal;
