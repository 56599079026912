import React, { useMemo, useState } from "react";
import BusyIndicator from "../../../../../../components/busy-indicator";
import OrderService from "../../../../../../services/OrderService";
import PageContainer from "../../../../components/page-container";
import OrdersListHeader from "./components/header";
import useQueryString from "../../../../../../hooks/useQueryString";
import PrintableOrder from "./components/printable-order";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useTable } from "react-table";
import { PromoCodeDTO, PromoType } from "../../../../../../dtos/promoCodeDTO";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";
import { TimeScale } from "./components/header/props";
import { UserPaymentMethod } from "../../../../../../dtos/userDTO";
import { utcToZonedTime, format } from "date-fns-tz";
import { OrderLogDTO, OrderLogType } from "../../../../../../dtos/orderLogDTO";
import "./style.css";

const OrdersList = () => {
  // Attributes
  const timeZone = "Europe/Berlin";
  const history = useHistory();
  const [timeScale, setTimeScale] = useState<TimeScale>(TimeScale.Day);
  const orderType = useQueryString().get("type");
  const [allOrdersQueryEnabled, setAllOrdersQueryEnabled] = useState(true);
  const allOrdersQuery = useQuery(`orders-${orderType}`, refreshOrders, {
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
    enabled: allOrdersQueryEnabled,
  });

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "basketDTO.employeeDTO.user.email", // accessor is the "key" in the data
        Cell: ({ value }: { value: number }) => (
          <span style={{ color: "#7F85A2" }}>{value}</span>
        ),
      },
      {
        Header: "Company",
        accessor: "basketDTO.employeeDTO.company.name",
        Cell: ({ value }: { value: number }) => (
          <span style={{ color: "#7F85A2" }}>{value}</span>
        ),
      },
      {
        Header: "Time / Date",
        accessor: "modificationDate",
        Cell: ({ value }: { value: string }) => (
          <span style={{ color: "#7F85A2" }}>
            {value &&
              format(
                utcToZonedTime(new Date(value + "z"), timeZone),
                "HH:mm - dd/MM/yyyy"
              )}
          </span>
        ),
      },
      {
        Header: "Payment method",
        accessor: "paymentMethod",
        style: { color: "#7F85A2" },
        Cell: ({ value }: { value: number }) => (
          <span
            style={{ color: "#7F85A2" }}
          >{`${UserPaymentMethod[value]}`}</span>
        ),
      },
      {
        Header: "Price",
        accessor: "totalPrice",
        style: { color: "#7F85A2" },
        Cell: ({ value }: { value: number }) => (
          <span style={{ color: "#7F85A2" }}>{`${value.toFixed(2)}€`}</span>
        ),
      },
      {
        Header: "Status",
        accessor: "orderLogs",
        style: { color: "#7F85A2" },
        Cell: ({ value }: { value: OrderLogDTO[] }) => {
          if (value?.length === 0) return <></>;
          return (
            <span
              style={{
                color: hasAlreadyBeenPrinted(value) ? "#0ead69" : "#000",
                fontWeight: "bold",
              }}
            >
              {hasAlreadyBeenPrinted(value) ? "PRINTED" : "CLICKED"}
            </span>
          );
        },
      },
      {
        Header: "Promo code",
        accessor: "basketDTO.promoCodeDTO",
        style: { color: "#7F85A2" },
        Cell: ({ value }: { value: PromoCodeDTO }) => (
          <span style={{ color: "#7F85A2" }}>
            {value
              ? `${value.name.toUpperCase()} - ${value.amount} ${
                  value.type === PromoType.PERCENTAGE ? "%" : "€"
                }`
              : "/"}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "col3",
        Cell: ({ cell }: any) => {
          const order = cell.row.original;
          return (
            <PrintableOrder
              order={order}
              setAllOrdersQueryEnabled={setAllOrdersQueryEnabled}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "col2",
        Cell: ({ cell }: any) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleClick(cell?.row?.original.id)}
          >
            <ArrowRight />
          </div>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: allOrdersQuery.isSuccess ? allOrdersQuery.data : [],
    });

  // Handlers
  async function refreshOrders() {
    return (await OrderService.getAll(orderType ?? "day")).data;
  }

  function handleClick(orderId: string, params: string = "") {
    history.push(`/dashboard/orders/${orderId}/${params}`);
  }

  function hasAlreadyBeenPrinted(orderLogs: OrderLogDTO[]) {
    return orderLogs?.find((elt) => elt.orderLogType === OrderLogType.Printed);
  }

  // Render
  return (
    <BusyIndicator query={allOrdersQuery}>
      <PageContainer>
        <OrdersListHeader
          totalOrders={allOrdersQuery.data && allOrdersQuery.data.length}
          timeScale={timeScale}
          setTimeScale={setTimeScale}
        />
        <div className="orders-table-container">
          <table {...getTableProps()} className="orders-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* Apply the table body props */}
            <tbody style={{ textAlign: "center" }} {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr
                      style={{
                        backgroundColor: row.values.orderLogs?.find(
                          (elt: OrderLogDTO) =>
                            elt.orderLogType === OrderLogType.Printed
                        )
                          ? "#ffeab894"
                          : "",
                      }}
                      {...row.getRowProps()}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </PageContainer>
    </BusyIndicator>
  );
};

export default OrdersList;
