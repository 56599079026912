import React, { useState } from "react";
import ActionButtonProps, { ACTION_ICON } from "./props";
import Icons from "./assets/index";
import "./style.css";
import Translation from "../translation";
import polygon from "./assets/polygon.png";
import Tooltip from "../../modules/dashboard/components/sidebar/components/tooltip";

const ActionButton = ({
  value,
  name,
  handleAction,
  style,
  isActive,
  type = "bottom",
  isBeta = false,
}: ActionButtonProps) => {
  // Attributes
  const [isHover, setIsHover] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  // Functions
  function renderIcon() {
    switch (name) {
      case ACTION_ICON.COMPANIES:
        return <Icons.CompanyIcon />;
      case ACTION_ICON.DISHES:
        return <Icons.DishIcon />;
      case ACTION_ICON.DETAILS:
        return <Icons.InfoIcon />;
      case ACTION_ICON.RESTAURANTS:
        return <Icons.RestaurantIcon />;
      case ACTION_ICON.EMPLOYEES:
        return <Icons.UserIcon />;
      case ACTION_ICON.STATISTICS:
        return <Icons.StatisticsIcon />;
      case ACTION_ICON.PROMO:
        return <Icons.PromoIcon />;
      case ACTION_ICON.ORDERS:
        return <Icons.OrdersIcon />;
      case ACTION_ICON.NEW_ORDERS:
        return <Icons.OrdersIcon />;
      case ACTION_ICON.NOTIFICATIONS:
        return <Icons.NotificationsIcon />;
      case ACTION_ICON.INDEX:
        return <Icons.PercentIcon />;
      case ACTION_ICON.CLIENT:
        return <Icons.ClientIcon />;
      case ACTION_ICON.ZONES:
        return <Icons.ZoneIcon />;
      case ACTION_ICON.APP_VERSIONING:
        return <Icons.AppVersioningIcon />;
      case ACTION_ICON.SCREENS:
        return <Icons.Screens />;
      case ACTION_ICON.PREPARATIONS_TIME:
        return <Icons.PreparationTime />;
    }
  }

  function renderClassName() {
    if (isActive) {
      return "action-button active";
    }

    return "action-button";
  }

  function renderPopOverClassName(className: string) {
    return `${className}-${type}`;
  }

  function handleMouseEnter(e: any) {
    setIsHover(true);
    const rect = e.target.getBoundingClientRect();
    setTooltipPosition({
      top: type === "bottom" ? rect.bottom : rect.top,
      left: type === "bottom" ? rect.left : rect.right,
    });
  }

  return (
    <div
      className="action-button-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        className={renderClassName()}
        onClick={handleAction}
        style={{ ...style }}
      >
        {value ? (
          <div className="action-button-value-container">
            <div className="action-button-value">{value}</div>
            <div style={{ height: "fill" }}>{renderIcon()}</div>
          </div>
        ) : (
          renderIcon()
        )}
        {!!isBeta && (
          <div className="tag-beta-container">
            <div className="tag-beta-text">beta</div>
          </div>
        )}
      </div>
      {isHover && (
        <Tooltip position={tooltipPosition}>
          <div className={renderPopOverClassName("table-action-pop-over")}>
            <div
              className={renderPopOverClassName(
                "table-action-pop-over-polygon"
              )}
            >
              <img
                className="table-action-pop-over-polygon-image"
                src={polygon}
                alt=""
              />
            </div>
            <div
              className={renderPopOverClassName("table-action-pop-over-body")}
            >
              <Translation>{name}</Translation>
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionButton;
